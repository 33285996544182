import "./App.css";
import { SlideCountdown } from "react-fancy-countdown";

function App() {
  return (
    <div className="App">
      {/* <div className="videoWrapper">
        <video controls allowfullscreen>
          <source src={video} type="video/mp4" />
        </video>
      </div> */}
      <SlideCountdown deadline="2024-05-05 00:00:00" />
    </div>
  );
}

export default App;
